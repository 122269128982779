/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

// import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Head from 'next/head';
import { useRouter } from "next/router";
// import InstagramFeed from "react-ig-feed";

import FooterNavComponent from "../components/FooterComponent/FooterNavComponent/FooterNavComponent";
import GetInTouchComponent from "../components/FooterComponent/GetInTouchComponent/GetInTouchComponent";
import RecentCitiesComponent from "../components/FooterComponent/RecentCitiesComponent/RecentCitiesComponent";
import HomeHeader from "../components/HeaderComponent/HomeHeader";
import AboutUsComponent from "../components/HomeComponent/AboutUsComponent/AboutUsComponent";
import FeaturedVideoComponent from "../components/HomeComponent/FeaturedVideoComponent/FeaturedVideoComponent";
import TestimonialComponent from "../components/HomeComponent/TestimonialComponent/TestimonialComponent";
import TopCitiesComponent from "../components/HomeComponent/TopCitiesComponent/TopCitiesComponent";
import TrustedByComponent from "../components/HomeComponent/TrustedByComponent/TrustedByComponent";
import { NextHead } from "../components/NextHead/NextHead";
// import Rotate from "../pages/"
import { AxiosInstance } from "../utilities";

const initialCMSdata = () => {
  return {
    heading: "",
    subHeading: "",
    trustedBy: [],
    featureVideos: [],
    testimonials: [],
    featureVideosHeading: {},
    about_us: {},
    about_us_vision: {},
    about_us_mission: {},
    about_us_images: [],
    top_cities_heading: "",
    testimonials_heading: "",
    backgroundVideo: "", //Added to seed data for HomeHeaderComponent
  };
};

export default function Home(props) {
  const [footerData, setFooterData] = useState(props.footerData || null);
  const [showAll, setShowAll] = useState(true);
  const [topCities, setTopCities] = useState(props.topCitiesData || []);
  const [cmsData, setCmsData] = useState(props.homeCmsData);
  const [featureVideo, setfeatureVideo] = useState({
    source: "",
    thumbnail: "",
  });
  const [aboutUsCms, setAboutUsCms] = useState({
    about_us: null,
    mission: null,
    vision: null,
  });

  const router = useRouter();
  const asPath = useRouter();

  useEffect(() => {
    if(asPath.asPath.includes("/?")){
      // to prevent changing of ? into %3F
    } else {
      if (router.asPath != "/") {
        router.push({ pathname: router.asPath });
      } else {
        if (props.homeCmsData) {
          setCmsData({ ...cmsData, ...props.homeCmsData });
        }
  
        if (props.featuredVideo) {
          setfeatureVideo({ ...featureVideo, ...props.featuredVideo });
        }
  
        if (props.aboutUsCmsData) {
          setAboutUsCms({ ...aboutUsCms, ...props.aboutUsCmsData });
        }
        if (Array.isArray(props.topCitiesData)) {
          setTopCities([...props.topCitiesData]);
        }
        if (props.footerData) {
          setFooterData({ ...props.footerData });
        }
      }
    }
  }, []);

  useEffect(() => {
    let data = {
      showAll: showAll,
    };
    // fetch_homepage_cms_data();
    fetch_footer_cms_data(data);
    // fetch_about_us_cms_data();
    // fetch_top_cities();

    if (props.previewKey) {
      fetch_homepage_cms_data();
      fetch_about_us_cms_data();
      fetch_top_cities();
    }
    fetch_top_cities();
  }, [showAll]);

  const fetch_homepage_cms_data = async () => {
    // let previewId = props.match?.params?.previewId;
    let params = { slug: "home" };
    if (props.previewKey) {
      params.previewKey = props.previewKey;
    }

    AxiosInstance(4008, "application/json")
      .post("/pages", params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const response_Data = response.data;
          const cmsDataClone = { ...cmsData };
          response_Data &&
            response_Data.data.map((item) => {
              if (item.key === "heading") {
                cmsDataClone.heading = item.value;
              }
              if (item.key === "subHeading") {
                cmsDataClone.subHeading = item.value;
              }
              if (item.key === "trustedBy") {
                cmsDataClone.trustedBy = item.content;
              }
              if (item.key === "featred_videos") {
                cmsDataClone.featureVideos = item.content;
                if (item.content.length > 0) {
                  const featureVideoClone = { ...featureVideo };
                  featureVideoClone.source = item.content[0].value;
                  featureVideoClone.thumbnail = item.content[0].thumbnail;
                  setfeatureVideo(featureVideoClone);
                }
              }
              if (item.key === "testimonials") {
                cmsDataClone.testimonials = item.content;
              }
              if (item.key === "featured_videos_heading") {
                cmsDataClone.featureVideosHeading = item;
              }
              if (item.key === "about_us") {
                cmsDataClone.about_us = item;
              }
              if (item.key === "about_us_mission") {
                cmsDataClone.about_us_mission = item;
              }
              if (item.key === "about_us_vission") {
                cmsDataClone.about_us_vision = item;
              }
              if (item.key === "about_us_images") {
                cmsDataClone.about_us_images = item.content;
              }

              if (item.key === "top_cities_heading") {
                cmsDataClone.top_cities_heading = item.value;
              }
              if (item.key === "testimonial_heading") {
                cmsDataClone.testimonials_heading = item.value;
              }
              if (item.key === "backgroundVideo") {
                cmsDataClone.backgroundVideo = item.value;
              }
            });
          setCmsData(cmsDataClone);
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };

  const fetch_about_us_cms_data = () => {
    AxiosInstance(4008, "application/json")
      .post("/pages", { slug: "about-us" })
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const response_Data = response.data;
          const aboutUsCmsClone = { ...aboutUsCms };
          response_Data &&
            response_Data.data.map((item) => {
              if (item.key === "about_us") {
                aboutUsCmsClone.about_us = item;
              }
              if (item.key === "mission") {
                aboutUsCmsClone.mission = item;
              }
              if (item.key === "vision") {
                aboutUsCmsClone.vision = item;
              }
            });
          setAboutUsCms(aboutUsCmsClone);
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };

  const fetch_footer_cms_data = (data) => {
    AxiosInstance(4008, "application/json")
      .post("/footerLinks", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const response_Data = response.data;
          setFooterData(response_Data);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          setFooterData(null);
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };

  const fetch_top_cities = () => {
    // let data = {
    //   latitude: 41.88183345689547,
    //   longitude: -87.66945636570976,
    // };
    AxiosInstance(4008, "application/json")
      .post("/cities")
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const response_Data = response.data;
          setTopCities(response_Data);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          setTopCities([]);
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };
  // const cities = topCities.sort(function(a, b) {
  //     return (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0;
  // })
  

  const show_more_handler = () => {
    setShowAll(!showAll);
    let data = {
      showAll: "",
    };
    fetch_footer_cms_data(data);
  };

  const show_less_handler = () => {
    setShowAll(!showAll);
    let data = {
      showAll: true,
    };
    fetch_footer_cms_data(data);
  };

  const onNavigateTo = (path) => {
    router.push(path);
  };

  const changeFeatureVideo = (item) => {
    const featureVideoClone = { ...featureVideo };
    featureVideoClone.source = item.value;
    featureVideoClone.thumbnail = item.thumbnail;
    setfeatureVideo(featureVideoClone);
  };

  return (
    <div>
      <Head>
        <link rel="canonical" href={`${process.env.BASE_URL}/`} />
      </Head>
      <main >
        <NextHead {...props?.seo}/>
        <HomeHeader cmsData={cmsData} />
        <FeaturedVideoComponent
          featureVideos={cmsData.featureVideos}
          featureVideo={featureVideo}
          changeFeatureVideo={changeFeatureVideo}
          onNavigateTo={onNavigateTo}
          featureVideosHeading={cmsData.featureVideosHeading}
        />
        <TrustedByComponent cmsData={cmsData} />
        <AboutUsComponent
          aboutUsCms={aboutUsCms}
          about_us={cmsData.about_us}
          about_us_vision={cmsData.about_us_vision}
          about_us_mission={cmsData.about_us_mission}
          about_us_images={cmsData.about_us_images}
          onNavigateTo={onNavigateTo}
        />
        {topCities?.length ? <TopCitiesComponent
          cities={topCities}
          top_cities_heading={cmsData.top_cities_heading}
          router={router}
        /> : ""}
        <div className="home_testimonial_container">
          <TestimonialComponent
            testimonials={cmsData.testimonials}
            testimonials_heading={cmsData.testimonials_heading}
          />
        </div>
        <div className="feeds_container" style={{display: 'flex', justifyContent: 'center'}}>
          <div className="feed_bg"></div>
          {/* <h2 className="feeds_heading">INSTAGRAM FEED</h2>
          <InstagramFeed
            token={process.env.INSTA_FEED_DEV_URL || ''}
            counter={15}
          /> */}
          <Button className="insta_section_btn">
            <a
              href="https://www.instagram.com/starlightmusicnyc"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit Starlight Music NYC Instagram Profile"
              style={{display: 'flex', alignItems: 'center'}}
            >
              View Our Instagram Page <ArrowRightOutlined style={{marginLeft: '10px', fontSize: '25px'}}/>
            </a>
          </Button>
          {/* <button
            className={styles.getintouch_section_btn}
            onClick={() => router.push("/contact-us")}
          >
            Get In Touch <img src="/assets/images/right_arrow.svg" alt="" />
          </button> */}
        </div>
        <RecentCitiesComponent
          footerData={footerData && footerData.cities ? footerData.cities : []}
          onNavigateTo={onNavigateTo}
          show_more={footerData && footerData.show_more}
          show_less={footerData && footerData.show_less}
          show_more_handler={show_more_handler}
          show_less_handler={show_less_handler}
        />
        <GetInTouchComponent />
        <FooterNavComponent footerData={props.footerData} />

      </main>
  
    </div>
  );
}

export async function getServerSideProps(context) {
  let homeCmsData = initialCMSdata();
  let featuredVideo = {};
  let topCitiesData = [];
  let footerData = {};
  let aboutUsCmsData = {};
  let seo = {};
  let aboutSeo = {};

  const fetch_homepage_cms_data = () => {
    return new Promise((resolve, reject) => {
      // let previewId = props.match?.params?.previewId;

      let params = { slug: "home" };
      // if (props.previewKey) {
      //   params.previewKey = props.previewKey;
      // }

      const cmsResult = {};
      const featuredVideosResult = {};
      AxiosInstance(4008, "application/json")
        .post("/pages", params)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            const response_Data = response.data;
            response_Data &&
              response_Data.data.forEach((item) => {
                if (item.key === "heading") {
                  cmsResult.heading = item.value;
                }
                if (item.key === "subHeading") {
                  cmsResult.subHeading = item.value;
                }
                if (item.key === "trustedBy") {
                  cmsResult.trustedBy = item.content;
                }
                if (item.key === "featred_videos") {
                  cmsResult.featureVideos = item.content;
                  if (item.content.length > 0) {
                    featuredVideosResult.source = item.content[0].value;
                    featuredVideosResult.thumbnail = item.content[0].thumbnail;
                  }
                }
                if (item.key === "testimonials") {
                  cmsResult.testimonials = item.content;
                }
                if (item.key === "featured_videos_heading") {
                  cmsResult.featureVideosHeading = item;
                }
                if (item.key === "about_us") {
                  cmsResult.about_us = item;
                }
                if (item.key === "about_us_mission") {
                  cmsResult.about_us_mission = item;
                }
                if (item.key === "about_us_vission") {
                  cmsResult.about_us_vision = item;
                }
                if (item.key === "about_us_images") {
                  cmsResult.about_us_images = item.content;
                }

                if (item.key === "top_cities_heading") {
                  cmsResult.top_cities_heading = item.value;
                }
                if (item.key === "testimonial_heading") {
                  cmsResult.testimonials_heading = item.value;
                }
                if (item.key === "backgroundVideo") {
                  cmsResult.backgroundVideo = item.value;
                }
              });
            seo = response_Data?.seo

            resolve({ cmsResult, featuredVideosResult, seo });
          }
        })
        .catch((err) => {
          console.log("err---", err?.message);
          reject(err);
        });
    });
  };

  const fetch_about_us_cms_data = () => {
    return new Promise((resolve, reject) => {
      const aboutUsResult = {};
      AxiosInstance(4008, "application/json")
        .post("/pages", { slug: "about-us" })
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            const response_Data = response.data;
            response_Data &&
              response_Data.data.map((item) => {
                if (item.key === "about_us") {
                  aboutUsResult.about_us = item;
                }
                if (item.key === "mission") {
                  aboutUsResult.mission = item;
                }
                if (item.key === "vision") {
                  aboutUsResult.vision = item;
                }
              });
              aboutSeo = response_Data?.seo
          }
          resolve(aboutUsResult);
        })
        .catch((err) => {
          console.log("err---", err?.message);
          reject(err);
        });
    });
  };

  const fetch_top_cities = () => {
    return new Promise((resolve, reject) => {
      
      AxiosInstance(4008, "application/json")
        .post("/cities")
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            const response_Data = response.data;
            resolve(response_Data);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            resolve([]);
          }
        })
        .catch((err) => {
          console.log("err---", err?.message);
          reject(err);
        });
    });
  };

  const fetch_footer_cms_data = (data) => {
    return new Promise((resolve, reject) => {
      AxiosInstance(4008, "application/json")
        .post("/footerLinks", { showAll: true })
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            const response_Data = response.data;
            resolve(response_Data);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log("err---", err?.message);
          reject(err);
        });
    });
  };

  let result;
  try {
    result = await fetch_homepage_cms_data();
    aboutUsCmsData = await fetch_about_us_cms_data();
    topCitiesData = await fetch_top_cities();
    footerData = await fetch_footer_cms_data();
  } catch (er) {
    console.error("error on home getServerSideProps");
  }

  return {
    // will be passed to the page component as props
    props: {
      homeCmsData: result?.cmsResult
        ? { ...homeCmsData, ...result.cmsResult }
        : homeCmsData,
      featuredVideo: result?.featuredVideosResult || {},
      aboutUsCmsData: aboutUsCmsData || {},
      topCitiesData: topCitiesData || [],
      footerData: footerData || {},
      seo: seo || {},
      aboutSeo: aboutSeo || {},
    },
  };
}
