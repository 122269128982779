import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import styles from "./RecentCitiesComponent.module.css";

const RecentCitiesComponent = (props) => {
  const router = useRouter();

  return (
    <section className={styles.recent_cities_container}>
      {props.footerData?.length ? <div className={styles.container_fluid}>
        <h3 className={styles.recent_cities_heading}>Our Recent Venues</h3>
        <ul
          className={`d-flex align-items-center justify-content-center flex-wrap ${styles.recent_cities_list}`}
        >
          {props.footerData &&
            props.footerData.length > 0 &&
            props.footerData.map((item, ind) => (
              <li
                className={styles.recent_cities_list_item}
                key={item._id}
              >
              <Link
                href={`/cities/${item.slug}`}
                passHref
              >
              <a className={styles.recent_cities_list_items}>
                {item.name}
              </a>
              </Link>
              </li>
            ))}
        </ul>
        {/* {props.show_more && props.show_more !== undefined && (
          <button
            className={`img_btn ${styles.recent_cities_btn}`}
            onClick={() => props.show_more_handler()}
            key={new Date()}
          >
            See More
          </button>
        )}

        {props.show_less && props.show_less !== undefined && (
          <button
            className={`img_btn ${styles.recent_cities_btn}`}
            onClick={() => props.show_less_handler()}
            key={new Date()}
          >
            See Less
          </button>
        )} */}
      </div> : ""}
    </section>
  );
};

export default RecentCitiesComponent;
