import React, { useState } from "react";

import { Button } from "antd";
import Interweave from "interweave";
import Image from "next/image";
import ReactPlayer from "react-player/lazy";
import Slider from "react-slick";

import styles from "./FeaturedVideoComponent.module.css";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FeaturedVideoComponent = (props) => {
  const [play, setPlay] = useState(false);

  const playNow = () => {
    setPlay(true);
  };

  const pauseNow = () => {
    setPlay(false);
  };

  return (
    <section className={styles.featured_video_container} id="featured-video">
      <div className={styles.featured_video_bg}></div>
      <figure
        className={styles.featured_video_thumpnail}
        style={{
          backgroundImage: `url(${
            process.env.IMAGE_LINK + props.featureVideo.thumbnail
          })`,
        }}
      ></figure>
      <div className={styles.container_fluid}>
        <div className="row align-items-center">
          <div className={`col-lg-6 ${styles.featured_video_content}`}>
            <h2 className={styles.featured_video_content_heading}>
              {props.featureVideosHeading
                ? props.featureVideosHeading.value
                : "Featured Videos"}
            </h2>
            <h3 className={styles.featured_video_content_title}>
              {props.featureVideosHeading
                ? props.featureVideosHeading.title
                : "Acoustic Rock"}
            </h3>
            <div className={styles.featured_video_content_para}>
              {!!props.featureVideosHeading && (
                <Interweave
                  allowElements={false}
                  content={props.featureVideosHeading.description}
                />
              )}
            </div>
            <button
              className={styles.featured_video_content_btn}
              onClick={play ? pauseNow : playNow}
            >
              <img
                src="/assets/images/play_icon.svg"
                alt="Play"
                className={`${styles.featured_video_content_btn_icon} ${
                  play ? "" : styles.show
                }`}
                width={15}
                height={19}
              />
              <img
                src="/assets/images/pause.svg"
                alt="Pause"
                className={`${styles.featured_video_content_btn_icon} ${
                  play ? styles.show : ""
                }`}
                width={14}
                height={18}
              />
              {play ? "Pause" : "Play Now"}
            </button>
          </div>
          <div
            className={`col-lg-6 d-flex justify-content-end ${styles.featured_video_player}`}
          >
            <ReactPlayer
              controls={true}
              playing={play}
              onPause={pauseNow}
              onPlay={playNow}
              url={
                props.featureVideo && props.featureVideo.source
                  ? props.featureVideo.source
                  : ""
              }
            />
          </div>
          <div className={`col ${styles.featured_video_slider}`}>
            <Slider {...settings}>
              {props.featureVideos && props.featureVideos.length > 0
                ? props.featureVideos.map((item) => (
                    <div
                      key={item._id}
                      onClick={() => props.changeFeatureVideo(item)}
                    >
                      <figure className={styles.featured_video_slider_figure}>
                        <Image
                          src={process.env.IMAGE_LINK + item.thumbnail}
                          alt={item.title}
                          width={385}
                          loading="lazy"
                          height={218}
                        />
                        <figcaption>{item.title}</figcaption>
                      </figure>
                    </div>
                  ))
                : ""}
            </Slider>
            <Button
              onClick={() => props.onNavigateTo("/our-talent")}
              className={styles.more_band_btn}
            >
              More Band Videos
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedVideoComponent;
