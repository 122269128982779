import React, { Component } from "react";

import Slider from "react-slick";

import styles from "./TopCitiesComponent.module.css";

export default class TopCitiesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });

    // Lazy load background for elements with the lazy_bg class
    const lazyBgElements = document.querySelectorAll(`.${styles.lazy_bg}`);
    if (lazyBgElements.length) {
      const observer = new IntersectionObserver(
        (entries, observerRef) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const el = entry.target;
              const bgUrl = el.getAttribute("data-bg");
              if (bgUrl) {
                el.style.backgroundImage = `url('${bgUrl}')`;
                observerRef.unobserve(el);
              }
            }
          });
        },
        { threshold: 0.1 }
      );

      lazyBgElements.forEach((el) => observer.observe(el));
    }
  }

  render() {
    return (
      <>
        {/* Main container with lazy-loaded background image */}
        <section 
          className={`${styles.top_cities_container} ${styles.lazy_bg}`}
          data-bg="/assets/images/top_cities_bg.png"
        >
          <div className="p-0 container-fluid">
            <h2 className={styles.top_cities_heading}>
              {this.props.top_cities_heading !== ""
                ? this.props.top_cities_heading
                : "Top Cities"}
            </h2>
            <section className={`position-relative ${styles.top_cities_section}`}>
              <div className={styles.top_cities_pic}>
                <Slider
                  asNavFor={this.state.nav2}
                  ref={(slider) => (this.slider1 = slider)}
                  slidesToShow={2}
                  arrows={false}
                  infinite={true}
                  className={styles.top_cities_pic_slide}
                  variableWidth={true}
                  responsive={[
                    {
                      breakpoint: 991,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {this.props.cities.map((city) => (
                    <div key={city._id}>
                      <figure className={`mb-0 ${styles.top_cities_pic_slide_img}`}>
                        <img
                          loading="lazy"
                          className="w-100"
                          src={process.env.IMAGE_LINK + city.thumbnail}
                          alt="top-cities"
                          width={310}
                          height={310}
                        />
                      </figure>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* Slider container with lazy-loaded background image */}
              <div
                className={`position-relative ${styles.top_cities_main} ${styles.lazy_bg}`}
                id="topCitiesSlideWrapper"
                data-bg="/assets/images/top_cities_world.png"
              >
                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  fade={true}
                  infinite={true}
                  className={styles.top_cities_main_slide}
                  nextArrow={
                    <button>
                      <img
                      loading="lazy"
                        src="/assets/images/cities_arrow.svg"
                        alt="arrow-icon"
                        width={16}
                        height={27}
                      />
                      <span>Next</span>
                    </button>
                  }
                  prevArrow={
                    <button>
                      <img
                      loading="lazy"
                        src="/assets/images/cities_arrow.svg"
                        alt="arrow-icon"
                        width={16}
                        height={27}
                      />
                      <span>Prev</span>
                    </button>
                  }
                >
                  {this.props.cities.map((city) => (
                    <div key={city._id}>
                      <div
                        className={`d-flex align-items-center justify-content-center flex-column ${styles.top_cities_main_slide_section}`}
                      >
                        <h3 className={styles.top_cities_main_slide_heading}>
                          {city.name}
                        </h3>
                        <p className={styles.top_cities_main_slide_para}>
                          {city.shortDescription}
                        </p>
                        <button
                          className={`d-inline-flex align-items-center justify-content-center img_btn ${styles.top_cities_main_slide_icon}`}
                          onClick={() =>
                            this.props.router.push(`/cities/${city.slug}`)
                          }
                        >
                          <img
                          loading="lazy"
                            src="/assets/images/flight_icon.svg"
                            alt="flight_icon"
                            width={50}
                            height={69}
                          />
                          <figcaption>GO</figcaption>
                        </button>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}
