/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { useViewportScroll, useTransform } from "framer-motion";
import Interweave from "interweave";
import Image from "next/image";

import styles from "./AboutUsComponent.module.css";

export function useOutsideAlerter(initialScrollPosition) {
  const ref = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(initialScrollPosition);
  useEffect(() => {
    function listenToScroll() {
      const position = window.pageYOffset;
      setScrollPosition(position);
    }

    // Bind the event listener
    window.addEventListener("scroll", listenToScroll);
    return () => {
      // Unbind the event listener on clean up
      window.addEventListener("scroll", listenToScroll);
    };
  }, [ref]);
  return { ref, scrollPosition, setScrollPosition };
}

const AboutUsComponent = (props) => {
  const [isComplete, setIsComplete] = useState(false);
  const { scrollPosition } = useOutsideAlerter(0);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);

  useEffect(() => yRange.onChange((v) => setIsComplete(v >= 1)), [yRange]);

  return (
    <section className={styles.aboutus_container}>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-6  d-flex align-items-start justify-content-center ps-md-0 ${styles.aboutus_image}`}
          >
            <figure>
              <div className="position-relative">
                <img
                  src="/assets/images/about_bg.svg"
                  alt="about-bg"
                  width={645}
                  height={645}
                  loading="lazy"
                />
                {props.about_us_images.map((value, index) =>
                  index === 0 ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                      src={`${process.env.IMAGE_LINK}${value.value}`}
                      alt="about-bg"
                      loading="lazy"
                      key={index}
                      className={styles.aboutus_image_photo}
                      style={{
                        width: `${scrollPosition - 1900}px`,
                      }}
                    />
                  ) : (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                      src={`${process.env.IMAGE_LINK}${value.value}`}
                      alt="about-bg"
                      key={index}
                      loading="lazy"
                      className={`${styles.aboutus_image_photo} ${styles.aboutus_image_photo_two}`}
                      style={{
                        width: `${scrollPosition - 1900}px`,
                      }}
                    />
                  )
                )}
              </div>
            </figure>
          </div>
          <div className={`col-lg-6 ${styles.aboutus_content}`}>
            <h2 className={styles.aboutus_content_heading}>
              {props.about_us ? props.about_us.value : ""}
            </h2>
            {props.about_us ? (
              <div className={styles.aboutus_content_section}>
                <h3 className={styles.aboutus_content_title}>
                  {props.about_us.title}
                </h3>
                <p className={styles.aboutus_content_para}>
                  <Interweave
                    allowElements={false}
                    content={props.about_us.description}
                  />
                </p>
                <figure
                  className={`d-lg-none ${styles.aboutus_content_section_image}`}
                >
                  {/* <img
                    src="/assets/images/about_img.png"
                    alt=""
                    width={690}
                    height={690}
                  /> */}
                    {props.about_us_images.map((value, index) =>
                  index === 0 ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                      src={`${process.env.IMAGE_LINK}${value.value}`}
                      alt="about-bg"
                      key={index}
                      className={styles.aboutus_content_section_image}
                      loading="lazy"
                      width={690}
                      height={690}
                    />
                  ) : (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                      src={`${process.env.IMAGE_LINK}${value.value}`}
                      alt="about-bg"
                      key={index}
                      className={`${styles.aboutus_image_photo} ${styles.aboutus_image_photo_two}`}
                      loading="lazy"
                      width={690}
                      height={690}
                    />
                  )
                )}
                </figure>
              </div>
            ) : (
              ""
            )}
            <div
              className={`${styles.aboutus_content_section} ${styles.aboutus_content_section_border}`}
            >
              <h6 className={styles.aboutus_content_section_heading}>
                {props.about_us_mission ? props.about_us_mission.value : ""}
              </h6>
              {props.about_us_mission ? (
                <>
                  <h3 className={styles.aboutus_content_title}>
                    {props.about_us_mission.title}
                  </h3>
                  <p className={styles.aboutus_content_para}>
                    <Interweave
                      allowElements={false}
                      content={props.about_us_mission.description}
                    />
                  </p>
                </>
              ) : (
                ""
              )}
              <figure
                className={`d-lg-none ${styles.aboutus_content_section_image}`}
              >
                <Image
                  src="/assets/images/about_img_2.png"
                  alt=""
                  width={690}
                  height={690}
                  loading="lazy"
                />
              </figure>
            </div>
            <div
              className={`${styles.aboutus_content_section} ${styles.aboutus_content_section_border}`}
            >
              <h6 className={styles.aboutus_content_section_heading}>
                {props.about_us_vision ? props.about_us_vision.value : ""}
              </h6>
              {props.about_us_vision ? (
                <>
                  <h3 className={styles.aboutus_content_title}>
                    {props.about_us_vision.title}
                  </h3>
                  {/* <p className="aboutus_content_para">{props.about_us_vision.description}</p> */}
                  <p className={styles.aboutus_content_para}>
                    <Interweave
                      allowElements={false}
                      content={props.about_us_vision.description}
                    />
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
            <button
              className={styles.know_more_btn}
              onClick={() => props.onNavigateTo("/about-us")}
            >
              About Starlight
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsComponent;
