/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Button } from "antd";

import styles from "./Header.module.css";

const Cursor = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };
  return (
    <div
      className={styles.cursor}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      <Button
        className={styles.cursor_video_btn}
        shape="circle"
        style={{ width: 60, height: 60 }}
        icon={
          props.fullScreen ? (
            <img
              src="/assets/images/close_button.svg"
              // width={60}
              // height={60}
              alt="Close"
            />
          ) : (
            <img
              src="/assets/images/play_button.svg"
              // width={60}
              // height={60}
              alt="Play"
            />
          )
        }
      />
    </div>
  );
};

export default Cursor;
